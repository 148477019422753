<!--
/*
 * @Author:张天鹏
 * @Date: 2020-03-07 15:03:31
 * @LastEditors: 刘旭
 * @LastEditTime: 2020-07-06 16:18:48
 */
 -->
<template>
  <div>
    <div class="orderformtop">
      <md-tab-bar v-model="current"
                  :items="items"
                  @change="onTabChange" />
      <div class="list"
           v-if="reportList.length"
           :style="{'height': (reportList.length ? '94vh' :'100vh')}">
        <md-scroll-view ref="scrollView"
                        :scroll-top="scrollTop"
                        auto-reflow
                        :scrolling-x="false"
                        @end-reached="loadMorechange"
                        :loading-text="listtotalPage < listpageNo || listtotalPage == listpageNo?'没有要加载的数据啦...':'正在加载中'">
          <div class="product"
               v-for="(item,index) of reportList"
               :key="index">
            <div @click="skip(item)">
              <div class="producttop">
                <div class="producttopl">
                  <p class="omit">{{item.prodname}}</p>
                </div>
                <div class="producttopr"
                     v-if="item.ddstatus=='0'">
                  <p><span>待支付</span><span class="marginleft"><img src="@/assets/cpk/images/time.png"
                           alt=""></span></p>
                </div>
                <div class="producttopr"
                     v-if="item.ddstatus=='1'">
                  <p><span class="succe">已完成</span><span class="marginleft"><img src="@/assets/cpk/images/dui.png"
                           alt=""></span></p>
                </div>
              </div>
              <div class="productcen">
                <div class="productcon">
                  <div class="productconl">
                    <p>投保人</p>
                  </div>
                  <div class="productconr">
                    <p>{{item.apidname}}</p>
                  </div>
                </div>
                <div class="productcon">
                  <div class="productconl">
                    <p>被保人</p>
                  </div>
                  <div class="productconr">
                    <p>{{item.pidname}}</p>
                  </div>
                </div>
                <div class="productcon">
                  <div class="productconl">
                    <p>投保时间</p>
                  </div>
                  <div class="productconr">
                    <p>{{item.appdate}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="productbom">
              <div class="productboml">
                <span class="wfont">保费<span class="hfont">{{item.mount}}</span>元</span>
              </div>
              <div class="productbomr"
                   :class="item.ddstatus=='0'?'deleteshow':''"
                   v-if="item.ddstatus=='0'">
                <md-button type="default"
                           size="small"
                           round
                           @click="deldata(item.orderno)">删除订单</md-button>
              </div>
              <div class="producttg"
                   v-if="item.ddstatus=='1'">
                <span class="wfont">推广费<span class="hfont">{{item.costvalue ? item.costvalue : '0.00'}}</span>元</span>
              </div>
              <div class="productbomr"
                   v-if="item.ddstatus=='1'&&item.yhjmount">
                <span class="wfont">奖励金<span class="hfont">{{item.yhjmount ? item.yhjmount : '0.00'}}</span>元</span>
              </div>
            </div>
          </div>
          <md-scroll-view-more slot="more"
                               :is-finished="listFinished" />
        </md-scroll-view>

      </div>
      <div v-else>
        <md-result-page v-if="!isPopupShow" :img-url="require('@/assets/abd/image/null.png')"
                      subtext="要不然刷新试试？" />
      </div>
    </md-tab-bar>
    </div>
        <md-popup v-model="isPopupShow" :mask-closable="closable">
          <div class="icdoling">
              <md-icon name="spinner" color="blue" size="lg" style="-webkit-filter:invert(1)"></md-icon>
          </div>
        </md-popup>
  </div>
</template>

<script>
import { Dialog } from 'mand-mobile'
import { orderformlist, orderformdelete } from '@/api/cpk/orderform/index'
import loadMorechange from '@/mixins/loadMorechange'

export default {
  data () {
    return {
      closable: false,
      isPopupShow: true,
      listFinished: false,
      loadflag: false,
      isNull: true,
      scrollTop: 0,
      ddstatus: 3,
      listpageNo: 1,
      pageSize: 6,
      loading: false,
      reportList: [],
      listtotalPage: 0,
      current: 3,
      isInit: false,
      items: [{ name: 3, label: '全部' }, { name: 0, label: '待处理' }, { name: 1, label: '已完成' }]
    }
  },
  mixins: [loadMorechange],
  created () {
    console.log('created')
    this.getData()
  },
  activated () {
    console.log('activated')
  },
  methods: {
    deldata (val) {
      Dialog.failed({
        title: '删除订单',
        content: '此操作将删除订单',
        confirmText: '确定',
        onConfirm: () => {
          orderformdelete({ orderno: val }).then(res => {
            this.listpageNo = 1
            this.reportList = []
            this.listFinished = false
            this.loadflag = false
            this.$refs.scrollView.finishLoadMore()
            this.getData()
          })
        }
      })
    },
    onTabChange (item, index, prevIndex) {
      if (item.label == '全部') {
        this.ddstatus = 3
      } else if (item.label == '待处理') {
        this.ddstatus = 0
      } else if (item.label == '已完成') {
        this.ddstatus = 1
      }
      this.listpageNo = 1
      this.reportList = []
      this.listFinished = false
      this.loadflag = false
      if (this.reportList.length !== 0) {
        this.$refs.scrollView.finishLoadMore()
      }
      this.getData()
    },
    skip (row) {
      if (row.ddstatus == '1') {
        // console.log('document.scrollingElement.scrollTop')
        // console.log(document.getElementsByClassName('list')[0].scrollTop)
        // console.log('document.documentElement.scrollTop')
        // console.log(document.getElementsByClassName('list')[0].scrollTop)
        window.location.href = row.orderurl
      } else if (row.ddstatus == '0') {

      }
    },
    getData () {
      let data = {
        page: this.listpageNo,
        size: this.pageSize,
        ddstatus: this.ddstatus == 3 ? this.ddstatus = '' : this.ddstatus
      }
      this.isPopupShow = true
      orderformlist(data).then(res => {
        // res.data.data.rows = [
        //   { prodname: '1', ddstatus: '1' },
        //   { prodname: '2', ddstatus: '1' },
        //   { prodname: '3', ddstatus: '1' },
        //   { prodname: '4', ddstatus: '1' },
        //   { prodname: '5', ddstatus: '1' },
        //   { prodname: '6', ddstatus: '1' },
        //   { prodname: '7', ddstatus: '1' },
        //   { prodname: '8', ddstatus: '1' },
        //   { prodname: '9', ddstatus: '1' },
        //   { prodname: '10', ddstatus: '1' },
        //   { prodname: '11', ddstatus: '1' }
        // ]
        this.reportList =
          this.reportList == []
            ? res.data.data.rows
            : this.reportList.concat(res.data.data.rows)
        if (this.reportList.length == 0) {
          this.isNull = true
        } else {
          this.isNull = false
        }

        this.total = res.data.data.total
        this.listtotalPage = res.data.data.totalpage
        if (this.listtotalPage <= this.listpageNo) {
          this.listFinished = true
        } else {
          this.listFinished = false
          if (this.reportList.length !== 0) {
            this.$nextTick(() => {
              this.$refs.scrollView.finishLoadMore()
            })
          }
        }
        this.isPopupShow = false
        this.loadflag = true
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
/deep/ .md-tab-bar {
  background: #fff;
}

.marginleft {
  margin-left: 10px;
}

/deep/ .md-tab-bar-ink {
  width: 0px !important;
}

/deep/ .md-tab-bar-item {
  font-size: 0.35rem;
  font-weight: 700;
  margin-top: 5px;
}

/deep/ .is-active {
  border-bottom: 5px solid;
}

.omit {
  margin-left: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.product {
  margin-bottom: 25px;
}

.producttop {
  background: #fff;
  display: flex;
  height: 120px;
  line-height: 120px;
}

.producttopl {
  width: 85%;
  display: flex;
  align-items: center;
}

.producttopl img {
  border: 1px solid #F1DEE4;
  margin: 0 30px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.producttopr {
  width: 15%;
}

.producttopr p {
  display: flex;
}

.producttopr span {
  display: flex;
  align-items: center;
  font-size: 30px;
  color: red;
}

.producttopr img {
  width: 15px;
  height: 15px;
}

.producttopr .succe {
  color: #00ae28;
}

.productcen {
  background: #fff;
  padding-bottom: 30px;
}

.productcon {
  background: #fff;
  display: flex;
  height: 70px;
  line-height: 70px;
}

.productconl {
  width: 50%;
}

.productconl p {
  font-size: 37px;
  color: #989898;
  margin-left: 30px;
}

.productconr {
  width: 50%;
}

.productconr p {
  font-size: 37px;
  color: #514E51;
  float: right;
  margin-right: 30px;
}

.productbom {
  border-top: 1px solid #F6F4F5;
  background: #fff;
  display: flex;
  height: 150px;
  line-height: 150px;
}

.productboml {
  width: 30%;
  margin-left: 30px;
}

.productbomr {
  width: 25%;
  align-items: center;
  text-align: right;
  padding-right: 0.3rem;
}

.deleteshow {

  margin-left 45%
}

.producttg {
  width: 40%;
  text-align: center;
}

.productbom /deep/ .md-button.small {
  height: 100%;
  text-align: -webkit-right;
}

.productbomr /deep/ .md-button-inner {
  margin-left: 1px;
  color: #FF6767;
  border: 1px solid #FF6767;
  border-radius: 15px;
  width: 200px;
  height: 80px;
}

.wfont {
  color: #3D3D3D;
  font-size: 0.35rem;
}

.hfont {
  color: #FF6767;
  font-weight: 700;
  margin: 0 10px;
}
   /deep/ .icdoling .md-icon {
          width: 1.42rem;
          height: 1.42rem;
          line-height: 1.42rem;
      }
</style>
